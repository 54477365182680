@import "fonts.sass"
@import "tailwindcss/base"
@import "tailwindcss/components"
@import "tailwindcss/utilities"
*
  font-family: 'Nunito', sans-serif

tbody
  tr:nth-child(even)
    background: #F3F4F6

.w-fit
  width: fit-content

.light-scroll
  scrollbar-width: thin
  scrollbar-color: #dbdbdb #ffffff
  &::-webkit-scrollbar
    width: 12px

  &::-webkit-scrollbar-track
    background: #ffffff

  &::-webkit-scrollbar-thumb
    background-color: #dbdbdb
    border-top: 8px solid #ffffff
    border-bottom: 8px solid #ffffff
    border-left: 5px solid #ffffff
    border-right: 5px solid #ffffff

.small-transparent-scroll
  scrollbar-width: thin
  scrollbar-color: #dbdbdb transparent
  &::-webkit-scrollbar
    width: 12px

  &::-webkit-scrollbar-track
    background: transparent


.overflow-y-overlay
  overflow-y: overlay
  @supports (-moz-appearance: none)
    overflow-y: auto

.max-modal-height
  max-height: calc(100vh - 50px)

.fix-site--banner
    height: 40vh
    background: url("../../images/landing/banner.webp")
    background-size: cover
    background-position: top left

.grid-service
    display: grid
    gap: 1rem
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))

.box-service
    display: flex
    flex-direction: column
    align-items: center
    padding: 1rem
    gap: 0.5rem
    text-align: center
    border-radius: 0.5rem
    border: 1px solid #851616

.fix-site--banner-form
    background: url("../../images/landing/banner-form.webp")
    background-size: cover
    background-position: top left


@media (min-width: 768px) 
    .fix-site--banner 
        height: 60vh
        background-position: top center
    
    .fix-site--banner-form 
        background-position: center left